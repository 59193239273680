



import { Component, Prop, Vue } from 'vue-property-decorator'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import { FieldSizes } from '@/shared/classes/form/field'
import Project from '@/shared/modules/project/models/project.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormField from '@/shared/components/form/FormField.vue'
import permissions from '@/shared/helpers/permissions.helper'
import can from '@/shared/helpers/can.helper'
import __ from '@/shared/helpers/__'

@Component({
  components: { FormField },
  methods: { can }
})
export default class ProjectSelect extends Vue {
  @Prop() project!: Project
  permissions = permissions

  projectSelect: SearchableField | null = null

  created() {
    this.projectSelect = new SearchableField()
        .setEntryKey('project_uuid')
        .setValue(this.project.uuid)
        .setTitle(__('company.views.projects.show.all-projects'))
        .loadItems({
          endpoint: `company/${this.company.slug}/projects`,
          value: 'uuid',
          title: 'name',
          perPage: 1000,
        })
        .setHideDetails(true)
        .setDense(true)
        .setMeta({
          class: 'ml-3'
        })
        .setSize(FieldSizes.quarter)
        .setOnChange((value) => this.changeProject(value))
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  changeProject(item: string) {
    if (! item || this.project && this.project.uuid === item) return

    this.$router.push({
        name: this.$router.currentRoute.name as string,
        params: { uuid: item }
      })
  }

}
