





















import { Component, Prop, Vue } from 'vue-property-decorator'
import can from '@/shared/helpers/can.helper'
import Tab from '@/shared/classes/tab'

@Component({
  methods: {
    can,
  },
})
export default class Tabs extends Vue {
  @Prop() tabs!: Tab[]
  @Prop({ default: 'pa-4' }) contentClass!: string
  @Prop({ default: 'mb-2' }) dividerClass!: string
  activeTab: string = ''
}
