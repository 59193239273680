
















import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Tabs from '@/shared/components/Tabs.vue'
import Tab from '@/shared/classes/tab'
import permissions from '@/shared/helpers/permissions.helper'
import Project from '@/shared/modules/project/models/project.model'
import ProjectService from '@/shared/modules/project/project.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormField from '@/shared/components/form/FormField.vue'
import ProjectSelect from '@/company/components/views/projects/projects-show/ProjectSelect.vue'
import can from '@/shared/helpers/can.helper'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'

@Component({
  components: { ProjectSelect, FormField, Tabs, PageHeader },
  methods: { __, can }
})
export default class ProjectsShow extends Vue {
  permissions = permissions
  @Provide('project') getProject(): Project|null {
    return this.project
  }
  @Provide('reloadProject') async reloadProject() {
    this.project = await ProjectService.show(this.company.slug, this.$router.currentRoute.params.uuid)
  }
  @Watch('$route.params.uuid') private handleRouteChanges() {
    this.project = null
    this.loadProject(this.$router.currentRoute.params.uuid)
  }

  tabs: Tab[] = []
  project: Project|null = null

  created() {
    this.loadProject(this.$router.currentRoute.params.uuid)
  }

  async loadProject(uuid: string) {
    this.project = await ProjectService.show(this.company.slug, uuid)

    this.tabs = [
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabIndex)
        .setTitle(__('company.views.projects.show.tabs.index.title'))
        .setPermissions([permissions.company.projects.show]),
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabDocuments)
        .setTitle(__('company.views.projects.show.tabs.documents.title'))
        .setPermissions([permissions.company.groups.tree]),
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabReports)
        .setTitle(__('company.views.projects.show.tabs.reports.title'))
        .setPermissions([permissions.company.reports.projects])
        .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible),
      ! this.disabled ? new Tab()
        .setTo(CompanyRoutes.projectsShowTabTasksAndTime)
        .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible)
        .setTitle(__('company.views.projects.show.tabs.tasks-and-time.title')) : new Tab().setPermissions(['disabled-permission']),
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabViolations)
        .setTitle(__('company.views.projects.show.tabs.violations.title'))
        .setPermissions([
          permissions.company.violations.index,
          toggleablePermissions.violations.notAssigned
        ], true),
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabChecklists)
        .setTitle(__('company.views.projects.show.tabs.checklist.title'))
        .setPermissions([ permissions.company.projects.seeChecklists, permissions.company.checklists.index ], false, this.project)
        .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible),
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabEmployees)
        .setTitle(__('company.views.projects.show.tabs.employees.title'))
        .setPermissions([permissions.company.projectEmployees.index])
        .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible),
      new Tab()
        .setTo(CompanyRoutes.projectsShowTabSummaryTabInstructionsAssigned)
        .setTitle(__('company.views.projects.show.tabs.summary.title'))
        .setPermissions([toggleablePermissions.projects.summary])
        .setIsVisible(this.company?.isTimeAccountingVisible || this.company?.isToolsVisible),
    ]
  }

  copyProject() {
    ProjectService.copy(this.company, this.$router.currentRoute.params.uuid)
      .then((project: Project) => {
        this.$router.push({
          name: this.$router.currentRoute.name as string,
          params: { uuid: project.uuid }
        }).catch(() => {})
      })

    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.projects.show.copy-on-success')
    })
  }

  get disabled(): boolean {
    return this.company.uuid === '2242f02c-af85-47f7-ad0b-31fc26e1b502'
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.projectsIndex })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
