












import { Component, Prop, Vue } from 'vue-property-decorator'
import { FieldTypes, fieldTypesMap } from '@/shared/components/form/field-types'
import Field from '@/shared/classes/form/field'
import FormBase from '@/shared/classes/form/form-base'

@Component
export default class FormField extends Vue {
  @Prop() field!: Field
  @Prop() fullKey!: string
  @Prop() index!: number
  @Prop() form!: FormBase

  getComponent(type: FieldTypes) {
    if (fieldTypesMap.hasOwnProperty(type)) {
      return fieldTypesMap[type]
    }

    console.log(`${type} is not defined`)
  }

  get translatableFullKey(): string {
    if (! this.form) return this.fullKey

    if (this.form.translatable && this.field.translatable) {
      return this.fullKey + '.' + this.form.currentLanguage
    }

    return this.fullKey
  }
}
