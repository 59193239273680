export enum FieldTypes {
  text = 'text',
  email = 'email',
  password = 'password',
  number = 'number',
  file = 'file',
  multipleFile = 'multiple-file',
  select = 'select',
  multipleSelect = 'multiple-select',
  checkbox = 'checkbox',
  array = 'array',
  object = 'object',
  textArea = 'text-area',
  datePicker = 'date-picker',
  timePicker = 'time-picker',
  searchable = 'searchable',
  multipleSearchable = 'multiple-searchable',
  dropzone = 'dropzone',
  button = 'button',
  buttonFile = 'buttonFile',
  radio = 'radio',
  custom = 'custom',
  switch = 'switch',
  editorField = 'editorField',
  label = 'label',
}

export const fieldTypesMap: any = {
  [FieldTypes.text]: () => import('@/shared/components/form/field-types/TextField.vue'),
  [FieldTypes.email]: () => import('@/shared/components/form/field-types/EmailField.vue'),
  [FieldTypes.password]: () => import('@/shared/components/form/field-types/PasswordField.vue'),
  [FieldTypes.number]: () => import('@/shared/components/form/field-types/NumberField.vue'),
  [FieldTypes.file]: () => import('@/shared/components/form/field-types/FileField.vue'),
  [FieldTypes.multipleFile]: () => import('@/shared/components/form/field-types/MultipleFileField.vue'),
  [FieldTypes.select]: () => import('@/shared/components/form/field-types/SelectField.vue'),
  [FieldTypes.multipleSelect]: () => import('@/shared/components/form/field-types/MultipleSelectField.vue'),
  [FieldTypes.checkbox]: () => import('@/shared/components/form/field-types/CheckboxField.vue'),
  [FieldTypes.array]: () => import('@/shared/components/form/field-types/ArrayField.vue'),
  [FieldTypes.object]: () => import('@/shared/components/form/field-types/ObjectField.vue'),
  [FieldTypes.textArea]: () => import('@/shared/components/form/field-types/TextAreaField.vue'),
  [FieldTypes.datePicker]: () => import('@/shared/components/form/field-types/DatePickerFieldNormal.vue'),
  [FieldTypes.timePicker]: () => import('@/shared/components/form/field-types/TimePickerField.vue'),
  [FieldTypes.searchable]: () => import('@/shared/components/form/field-types/SearchableField.vue'),
  [FieldTypes.multipleSearchable]: () => import('@/shared/components/form/field-types/MultipleSearchableField.vue'),
  [FieldTypes.dropzone]: () => import('@/shared/components/form/field-types/DropzoneField.vue'),
  [FieldTypes.button]: () => import('@/shared/components/form/field-types/ButtonField.vue'),
  [FieldTypes.buttonFile]: () => import('@/shared/components/form/field-types/ButtonFileField.vue'),
  [FieldTypes.radio]: () => import('@/shared/components/form/field-types/RadioField.vue'),
  [FieldTypes.custom]: () => import('@/shared/components/form/field-types/CustomField.vue'),
  [FieldTypes.switch]: () => import('@/shared/components/form/field-types/SwitchField.vue'),
  [FieldTypes.editorField]: () => import('@/shared/components/form/field-types/EditorField.vue'),
  [FieldTypes.label]: () => import('@/shared/components/form/field-types/LabelField.vue'),
}
