const toggleablePermissions = {
  reports: {
    notAssignedProjects: 'toggleable.reports.not-assigned-projects'
  },
  violations: {
    notAssigned: 'toggleable.violations.not-assigned'
  },
  projects: {
    plannedHours: 'toggleable.project-list.planned-hours',
    budget: 'toggleable.project-list.budget',
    usedHours: 'toggleable.project-list.used-hours',
    balance: 'toggleable.project-list.balance',
    usedExtraHours: 'toggleable.project-list.used-extra-hours',
    summary: 'toggleable.projects.summary',
  }
}

export default toggleablePermissions
