import Service from '@/shared/classes/service'
import Project from '@/shared/modules/project/models/project.model'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IProjectResponse from '@/shared/modules/project/interfaces/project-response.interface'
import Company from '@/shared/modules/company/company.model'

export default class ProjectService extends Service {
  static prefix: string = 'projects'

  static show(companySlug: string, uuid: string): Promise<Project> {
    return this.get(`${ this.getStartUrl(companySlug) }/${ uuid }`)
      .then((response: IResponse<IProjectResponse>) => new Project(response.data))
  }

  static destroy(companySlug: string, uuid: string): Promise<any> {
    return this.delete(`${ this.getStartUrl(companySlug) }/${ uuid }`)
  }

  static getStartUrl(companySlug: string): string {
    return `company/${ companySlug }/${ this.prefix }`
  }

  static copy(company: Company, projectUuid: string): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }/${ projectUuid }/copy`, {})
      .then((response: IResponse<IProjectResponse>) => new Project(response.data))
  }
}
